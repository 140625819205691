<template>
	<div class="page">
		<div class="title">
			<p class="b">{{lang==6?'版权以及免责声明':'Copyright and Disclaimer'}}</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="text textEN" v-if="lang!=6">
				<p class="b">Introduction</p>
				<p>In order to ensure that users enjoy the convenience provided by the PAIG (China) website, we have set
					some basic instructions. You can learn more about our detailed policies and regulations in these
					Terms of Use and Privacy Policy. It is suggested to read carefully.</p>
				<p class="b">Terms and Conditions</p>
				<p>By accessing and browsing this website, you accept without limitation or qualification, the following
					terms and conditions of use. If you do not agree, please discontinue your use of this website.</p>
				<p class="b">Applicability</p>
				<p>Terms and Conditions of Use apply to all users who access and use the PAIG (China) website, as well
					as all information, advice and/or services provided through the website.</p>
				<p>By using this website, users accept these Terms of Use. Please note that these terms will be adjusted
					to the changes of the relevant regulations. The changes will be effective immediately upon revision
					of the Terms of Use. Website users are advised to read the Terms of Use periodically to understand
					the changes in the information.</p>
				<p class="b">Product Service Information and Responsibilities</p>
				<ul>
					<li>The information and graphic images published on this website are based on the technical
						parameters at the time of making and uploading related web pages.</li>
					<li>The description of each brand and its products on this website, including car model,
						configuration and accessories, is limited to vehicles sold in mainland China (excluding Hong
						Kong, Macao and Taiwan).</li>
					<li>The information displayed on this website is general information under any circumstances and
						is
						not legally binding.</li>
					<li>Website information is only used to provide relevant message and does not constitute any
						recommendation. For damages caused by using (or failing to use) this website, including losses
						caused by viruses, applicability, timeliness or accuracy of information on this website, except
						for damages caused by improper handling or serious negligence of PAIG (China), the company shall
						assume no responsibility for foresaid conditions.</li>
					<li>PAIG (China) shall assume no responsibility for any damages caused by the use of electronic
						communication means, including but not limited to losses caused by the failure or delay of
						electronic communication transmission, interception or processing of electronic information by
						third parties, or computer programs used for electronic communication and virus transmission.
					</li>
					<li>PAIG (China) does not make any guarantee for the following contents, including but not limited
						to the guarantee for conditions, quality, merchantability, applicability for specific purposes
						and non-infringement.</li>
				</ul>

				<p class="b">Third Party Information</p>
				<p>Information from a third party represents the personal opinions of that third party. PAIG (China) is
					not responsible or liable for such information.</p>
				<p>Hyperlinks on this website may guide visitors to external websites maintained by third parties. PAIG
					(China) does not assume any responsibility for the content and functions of these external websites.
					PAIG (China) does not assume any responsibility for the quality of products and services provided by
					these external websites.</p>
				<p class="b">Intellectual Property</p>
				<ul>
					<li>Users are not allowed to use any trademark displayed on this website without the prior explicit
						written consent of PAIG (China), except as otherwise provided by applicable laws. Other
						trademarks and logos displayed on this website may be owned by a third party related to or
						unrelated to PAIG (China) and used according to the permission of the third party. Nothing
						displayed on this website shall be construed as granting (by implication, estoppel rules or
						otherwise) any approval, permission or right to use any trademark, service mark or brand name
						displayed on this website without the written permission of the owner of PAIG (China) or a third
						party. Except with the written consent of PAIG (China), it is strictly prohibited to use any
						relevant logo or trademark (whether registered or not) of PAIG (China) as a hyperlink to this
						website or any other website.</li>
					<li>All text, images, sound files, animation files, video files and other materials on the website
						are protected by copyright and other intellectual property rights. No one is allowed to use
						these materials for commercial purposes or to disseminate them without the written consent of
						PAIG (China), nor is it allowed to modify or mirror them on PAIG (China) servers.</li>
					<li>Users are allowed to read this website and information, as well as copy aforesaid information
						for
						personal use, such as by printing or storage. All other uses of the website or information are
						prohibited by PAIG (China). For example, it is prohibited to store or copy the contents of PAIG
						(China) website on any external Internet website.</li>
				</ul>
				<p class="b">Comments and Suggestions from Users</p>
				<p>If the user voluntarily publishes opinions, suggestions and/or materials on this website, regardless
					of whether it contains text, pictures, sound, software, information or other forms of content, or
					sends these materials to PAIG (China) by email or other means, PAIG (China) is allowed to use, copy
					and/or use these materials in full, free of charge and through commercial means. PAIG (China) does
					not bear any obligation of confidentiality for these materials.</p>
				<p>From the date when the user publishes the opinions, suggestions and/or materials on this website,
					he/she will guarantee that the use and/or promotion of these materials will not infringe the rights
					(intellectual property rights) of any third party or cause any damage to any third party, resulting
					in any lawsuit, claim and debt. In the event of the above situation, it is necessary to make
					corresponding compensation to PAIG (China).</p>
				<p class="b">Principle of Independence</p>
				<p>If these Terms of Use are partially invalid in relevant laws, both parties will continue to be bound
					by the remaining terms. Both parties shall consider the content and intention of the Terms of Use
					and try to replace the invalid terms with valid and legally effective terms corresponding to the
					invalid terms.</p>
				<p class="b">Applicable Law and Jurisdiction</p>
				<p>These Terms of Use are exclusively governed by the laws of the People's Republic of China. Any
					dispute arising from or related to these Terms of Use (including but not limited to disputes over
					the existence and validity of these Terms of Use) shall be settled by the courts of the People's
					Republic of China.</p>
				<p class="b" style="font-size: 40px;margin: 35px 0">Privacy and Cookie Policies</p>
				<p class="b">General Information</p>
				<p>PAIG (China) is the manager who processes all personal data collected through this website (the
					"website"). PAIG (China) respects users' privacy and handles users' data in accordance with the
					relevant regulations of the People's Republic of China on handling personal information on the
					Internet. This privacy and Cookie policy (the "privacy policy") will describe how we handle all
					personal data collected through websites and our right to access and correct these personal data.
				</p>
				<p>PAIG (China) has the right to change relevant policies. The change information will take effect
					immediately when the changed policy is released. Website users are advised to read the policies
					periodically to understand the changes in the information.</p>
				<p class="b">Personal Data</p>
				<p>PAIG (China) handles personal data collected through the website. The scope of personal data
					collected includes but is not limited to:</p>
				<ul>
					<li>Name</li>
					<li>Date of Birth</li>
					<li>Name of affiliated company</li>
					<li>Contact information</li>
					<li>Other information (e-mail, mobile phone, etc.) that you enter on the form of the website</li>
					<li>Any personal data contained in the content uploaded to the website by the user</li>
				</ul>
				<p>For users under the age of 18, their personal data can be submitted through the website of PAIG
					(China) only if their legal guardians have read this privacy policy and are permitted to do so.</p>
				<p class="b">Usage</p>
				<p>PAIG (China) will use personal data for the following purposes:</p>
				<ul>
					<li>Provide users with the services shown on the website;</li>
					<li>Improve service quality;</li>
					<li>Answer users' questions;</li>
					<li>Send information about current or future events organized by PAIG (China). If the law provides,
						PAIG (China) will obtain users' permission before sending such information.</li>
				</ul>
				<p class="b">Receiver</p>
				<p>PAIG (China) may transfer users' personal data to its group company. PAIG (China) may also provide
					users' personal data to a third party, which will process the personal data according to the
					instructions of PAIG (China), and the relevant responsibilities shall be borne by PAIG (China). If
					PAIG (China) sells all or part of the company's assets or equity to a third party, it may also
					provide users' personal data to the third party at the same time. Unless explicitly required by
					legal obligations, PAIG (China) will not provide users' personal data to any other third party.</p>
				<p>Once use any services provided on this website, users explicitly authorize PAIG (China) to transfer
					his/her personal data to third countries/regions, even if these countries/regions cannot provide
					sufficient data protection services.</p>
				<p class="b">Cookies</p>
				<p>This website uses the so-called "Cookie". Cookies are small text files stored on the user's local
					computer. If visit a website, the website will use Cookie to identify the user. In this way, PAIG
					(China) can improve the user-friendliness of the website and adjust the website according to users'
					needs. The website Cookie stored on the user's computer will remain valid indefinitely after the
					user first visit to the website.</p>
				<p>Users can change the settings of his/her web browser to notify him/her when a Cookie is stored on
					his/her computer. Users can also change the settings so that the browser does not accept Cookie from
					this website. However, if the user's browser does not accept Cookie from this website, the user will
					not be able to access or use all the features of this website.</p>
				<p class="b">Security and Retention Period</p>
				<p>When transmitting, storing and processing personal data collected through this website, we will adopt
					currently commonly used technical measures to ensure the safety of the data.</p>
				<p>In general, the period of time that PAIG (China) retains personal data collected through this website
					will not exceed two years.</p>
				<p class="b">Access, Correction and Deletion</p>
				<p>At any time, any user can request that PAIG (China) provide him/her with access to the personal data
					it collects about the user. Users can also request PAIG (China) to correct or delete these personal
					data.</p>
			</div>
			<div class="text" v-if="lang == 6">
				<p class="b">简介</p>
				<p>为了确保用户享有宝爱捷（中国）网站提供的便利，我们设定了一些基本指导说明。您可以在这些使用条款和隐私政策中了解我们的详细政策和规定。建议您仔细阅读。</p>
				<p class="b">条款和条件</p>
				<p>通过访问和浏览本网站，您无条件接受以下使用条款与条件。如果您不同意下述条款和条件，请停止使用本网站。</p>
				<p class="b">适用性</p>
				<ul>
					<p>使用条款和条件适用于所有访问和使用宝爱捷（中国）网站的用户，以及通过网站向您提供的所有信息、建议和/或服务。</p>
					<p>如果使用本网站，则表明您接受这些使用条款。请注意，这些条款将根据相关规定的变更作出调整。变更信息将在修改使用条款发布时立即生效。建议网站用户定期阅读使用条款，了解信息的变更情况。</p>
					<p>产品服务信息以及责任</p>
					<p>本网站公布的信息以及图形图像以在相关网页制作及上载时的技术参数为基准。</p>
					<p>本网站对各品牌及其产品的描述，包括汽车型号、配置、附件，仅限于在中国大陆市场(不含香港、澳门、台湾地区)销售的车辆。</p>
					<p>本网站包含的信息在任何情况下均为一般性信息，不具有法律约束力。</p>
					<p>站内信息仅用于提供相关信息，并非建议内容。由于使用本网站（或不能使用）造成的损失，除由于宝爱捷（中国）处理不当或严重疏忽造成的损失,公司不承担任何责任,包括由于病毒、本网站信息适用性、及时性或准确性造成的损失，宝爱捷（中国）不承担任何责任。
					</p>
					<p>宝爱捷（中国）对因使用电子通信手段引起的任何损失不承担任何责任，包括但不限于因电子通讯传送的故障或延迟，第三方拦截或处理电子信息，或用于电子通讯的计算机程序以及病毒传播引起的损失。</p>
					<p>宝爱捷（中国）对以下内容不作任何保证，包括但不限于对条件、质量、适销性、特定用途的适用性以及不侵权所作的保证。</p>
				</ul>
				<p class="b">产品服务信息以及责任</p>
				<p>本网站公布的信息以及图形图像以在相关网页制作及上载时的技术参数为基准。</p>
				<p>本网站对各品牌及其产品的描述，包括汽车型号、配置、附件，仅限于在中国大陆市场(不含香港、澳门、台湾地区)销售的车辆。</p>
				<p>本网站包含的信息在任何情况下均为一般性信息，不具有法律约束力。</p>
				<p>站内信息仅用于提供相关信息，并非建议内容。由于使用本网站（或不能使用）造成的损失，除由于宝爱捷（中国）处理不当或严重疏忽造成的损失,公司不承担任何责任,包括由于病毒、本网站信息适用性、及时性或准确性造成的损失，宝爱捷（中国）不承担任何责任。
				</p>
				<p>宝爱捷（中国）对因使用电子通信手段引起的任何损失不承担任何责任，包括但不限于因电子通讯传送的故障或延迟，第三方拦截或处理电子信息，或用于电子通讯的计算机程序以及病毒传播引起的损失。</p>
				<p>宝爱捷（中国）对以下内容不作任何保证，包括但不限于对条件、质量、适销性、特定用途的适用性以及不侵权所作的保证。</p>
				<p class="b">第三方信息</p>
				<p>源自第三方的信息表示该第三方的个人意见。宝爱捷（中国）不对这类信息负责或承担任何责任。</p>
				<p>本网站的超链接可能引导访客访问由第三方维护的外部网站。宝爱捷（中国）对这些外部网站的内容和功能不承担任何责任。宝爱捷（中国）对这些外部网站所提供产品和服务的质量不承担任何责任。</p>
				<p class="b">知识产权</p>
				<ul>
					<p>非经宝爱捷（中国）的事先明确书面许可，您不得使用本网站上出现的任何商标，但适用法律允许的除外。本网站上所显示的其它商标和标识可能为与宝爱捷（中国）有关或无关的第三方所拥有，并根据第三方的许可而使用。非经宝爱捷（中国）或第三方所有者的书面许可，本网站上所显示的任何内容不得被理解为授予（通过暗示、禁反言规则或其它方式）使用本网站上展示的任何商标、服务标识或品牌名称之批准、许可或权利。除非由宝爱捷（中国）书面同意，严禁使用任何宝爱捷（中国）相关标识或商标（无论是否注册）作为连接到本网站或任何其它网站的超链接。
					</p>
					<p>网站上所有文字、图像、声音文件、动画文件、视频文件及其他资料均受版权及其他知识产权保护。未经宝爱捷（中国）书面许可，任何人不得将这些资料用于商业用途或私自传播，亦不可修改或在宝爱捷（中国）的服务器上做镜像。
					</p>
					<p>允许用户阅读本网站和信息，以及复制用于个人用途，例如通过打印或存储。网站或信息的所有其他用途被宝爱捷（中国）予以禁止，例如禁止在任何外部互联网网站上存储或复制宝爱捷（中国）网站的内容。
					</p>
					<p>用户的意见及建议</p>
					<p>如果用户主动将意见、建议和/或资料发布在本网站上，无论其中是否包含文字、图片、声音、软件、信息或其他形式的内容，
						或者将这些资料通过电子邮件或其他方式发给宝爱捷（中国），即表示您允许宝爱捷（中国）全权、免费使用、复制和/或通过商业手段使用这些资料。宝爱捷（中国）对这些资料不承担任何保密义务。</p>
					<p>用户将意见、建议和/或资料发布在本网站之日起，即表示保证宝爱捷（中国）不会因使用和/或宣传这些资料侵犯任何第三方的权利（知识产权）或者给第三方带来任何损害而受到任何诉讼、索赔和债务。一旦发生上述情况，需要对宝爱捷（中国）作出相应的赔偿。
					</p>
				</ul>
				<p class="b">用户的意见及建议</p>
				<p>如果用户主动将意见、建议和/或资料发布在本网站上，无论其中是否包含文字、图片、声音、软件、信息或其他形式的内容，
					或者将这些资料通过电子邮件或其他方式发给宝爱捷（中国），即表示您允许宝爱捷（中国）全权、免费使用、复制和/或通过商业手段使用这些资料。宝爱捷（中国）对这些资料不承担任何保密义务。
					用户将意见、建议和/或资料发布在本网站之日起，即表示保证宝爱捷（中国）不会因使用和/或宣传这些资料侵犯任何第三方的权利（知识产权）或者给第三方带来任何损害而受到任何诉讼、索赔和债务。一旦发生上述情况，需要对宝爱捷（中国）作出相应的赔偿。
				</p>
				<p class="b">独立性原则</p>
				<p>如果本使用条款在相关法律中部分无效，双方将继续受剩余条款的约束。双方应考虑本使用条款的内容和意图，尽量使用与无效条款相应的有效且有法律效力的条款代替无效条款。</p>
				<p>适用的法律和管辖权</p>
				<p>本使用条款受中华人民共和国法律的专属管辖。本使用条款引起的或与其相关的任何纠纷（包括但不限于关于本使用条款存在和效力的纠纷）应由中华人民共和国法院予以解决。</p>
				<p class="b">隐私和 Cookie 政策</p>
				<p class="b">一般信息</p>
				<p>宝爱捷（中国）是处理通过本网站（简称“网站”）收集的所有个人资料的管理者。宝爱捷（中国）尊重您的隐私，遵照中华人民共和国相关处理网络个人信息的规定来处理您的资料。</p>
				<p>本隐私和 Cookie 政策（“隐私政策”）将介绍我们如何处理通过网站收集的所有个人资料，以及访问和更正这些个人资料的权利。</p>
				<p>宝爱捷（中国）享有变更相关政策的权利。这些变更信息在更改的政策发布时立即生效。建议网站用户定期阅读本政策，了解信息变更的情况。</p>
				<p class="b">个人资料</p>
				<p>宝爱捷（中国）处理通过网站收集的个人资料。所收集的个人资料范畴包括但不限于：</p>
				<ul>
					<p>姓名</p>
					<p>出生日期</p>
					<p>所属公司名称</p>
					<p>详细联系方式</p>
					<p>您在网站的表格上输入的其他信息（电子邮件、移动电话等）</p>
					<p>在用户上载到网站的内容中包含的任何个人资料</p>
				</ul>
				<p>对于不满 18 岁的用户，须在其法定监护人已经阅读本隐私政策并且许可的情况下，通过宝爱捷（中国）的网站提交个人资料。</p>
				<p class="b">用途</p>
				<p>宝爱捷（中国）会将您的个人资料用于以下用途：</p>
				<ul>
					<p>为您提供网站上所显示的服务；</p>
					<p>提高服务质量；</p>
					<p>回答您的问题；</p>
					<p>向您发送有关宝爱捷（中国）当前或未来所举办活动的信息。如果法律有规定，宝爱捷（中国）会在发送这类信息之前获得您的许可。</p>
				</ul>
				<p class="b">接收人</p>
				<p>宝爱捷（中国）可能会将您的个人资料转输至其集团公司。宝爱捷（中国）也可能将您的个人资料提供给第三方，该方将按照宝爱捷（中国）的指导处理您的个人资料，相关责任由宝爱捷（中国）承担。如果宝爱捷（中国）将公司的全部或部分资产或股权出售给第三方，也可能同时将您的个人资料提供给该第三方。除非法律义务明确要求，宝爱捷（中国）不会将您的个人资料提供给任何其他第三方。
				</p>
				<p>一旦使用本网站上提供的任何服务，即表示您明确许可宝爱捷（中国）将您的个人资料传送给第三国家/地区，即使这些国家/地区无法提供足够的资料保护服务。</p>
				<p class="b">Cookie</p>
				<p>本网站使用所谓的“Cookie”。Cookie 是存储在您的本地计算机上的一些小型文本文件。如果您访问某个网站，网站会使用 Cookie
					识别您的身份。这样，宝爱捷（中国）就可以改善网站的用户友好程度，并根据您的需求调整网站。存储在您的计算机上的网站 Cookie 将会在您首次访问网站之后无限期保持有效。</p>
				<p>可以将网络浏览器的设置更改为在有 Cookie 存储在您的计算机上时通知您。也可以更改设置，使浏览器不接受本网站的 Cookie。不过，如果您的浏览器不接受本网站的
					Cookie，您将无法访问或使用本网站的所有功能。</p>
				<p class="b">安全和保留期</p>
				<p>在传送、存储和处理通过本网站收集的个人资料时，我们会采用目前常用的技术措施确保资料的安全性。
					通常，宝爱捷（中国）保留通过本网站收集的个人资料的时间不会超过两年。</p>
				<p class="b">访问、更正和删除</p>
				<p>您可以随时请求宝爱捷（中国）为您提供访问其所收集的关于您的个人资料的权限。您也可以请求宝爱捷（中国）更正或删除这些个人资料。</p>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 70px;
		font-size: 18px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px;
	}

	.title {
		padding: 0px 111px;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		text-transform: uppercase;
		color: #FFF;
	}

	.b {
		font-weight: bold;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.text {
		padding: 0px 0 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;
	}

	.textEN {
		.b {
			margin-top: 30px;
			line-height: 46px;
			font-weight: bold;
			font-size: 22px;
		}

		ul {
			margin-left: 25px;
		}
	}
</style>